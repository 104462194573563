import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import LiveBlogModel from '../../../models/live-blog.model';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { sportsConnectionColorStyles } from '../../../../../Partials/Sidebar/tags-refactored/constants/styles';
import { customOption } from '../../../../../Partials/Sidebar/tags/subcomponents/football-connections/option-component';
import {
	extractTeamsFromEvent,
	filterTeamsArray,
} from '../../../../../Partials/Blocky/v2/partials/helpers/team-select/team-select-component.helper';
import '../../../style/live-blog.style.scss';
import LiveBlogTeamModel from '../../../models/teams/live-blog-team.model';
import { responseToLiveBlogFootballTeamModel, teamsToOptions } from '../../../models/teams/live-blog-team-model.mapper';

type Properties = {
	t: any;
	seasonId: string;
	liveBlog: LiveBlogModel;
	setSelectedLiveBlogTeams: (teams: LiveBlogTeamModel[]) => void;
};

export const LiveBlogTeamsSelect: React.FunctionComponent<Properties> = ({ t, setSelectedLiveBlogTeams, liveBlog, seasonId }) => {
	const [teamsOptions, setTeamsOptions] = useState<LiveBlogTeamModel[]>([]);
	const [selectedTeams, setSelectedTeams] = useState<LiveBlogTeamModel[] | null>(
		liveBlog.teams && liveBlog.teams.length > 0 ? liveBlog.teams : [],
	);

	useEffect(() => {
		setTeamsOptions([]);
		if (seasonId) {
			getTeams(seasonId);
		}
		setSelectedTeams(selectedTeams);
	}, [liveBlog.competitions]);

	const getTeams = (seasonId: string) => {
		if (seasonId) {
			return HttpService.getTeamsBySeasonId(seasonId)
				.then((response: any) => {
					setTeamsOptions(response.data.map((item: LiveBlogTeamModel) => responseToLiveBlogFootballTeamModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const onTeamsSelect = (teams: LiveBlogTeamModel[]) => {
		if (teams && teams.length > 0) {
			setSelectedTeams(teams.map((team: LiveBlogTeamModel) => team.data));
			setSelectedLiveBlogTeams(teams.map((team: LiveBlogTeamModel) => team.data));
		} else {
			setSelectedTeams(null);
			setSelectedLiveBlogTeams([]);
		}
	};
	return (
		<Row className='mb-3 teams'>
			<Col>
				<FormGroup>
					<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_TEAMS}>
						<strong>{t('teams')}</strong>
					</Label>
					<Select
						isMulti
						id={DATA_QA_ATTRIBUTES.LIVE_BLOG_TEAMS}
						styles={sportsConnectionColorStyles}
						options={teamsToOptions(teamsOptions)}
						value={selectedTeams && teamsToOptions(selectedTeams)}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						isClearable={true}
						placeholder={t('select')}
						formatOptionLabel={customOption}
						onChange={(selection: any) => onTeamsSelect(selection)}
					/>
					<h6 className='mt-1' style={{ color: 'gray' }}>
						{t('live_blog_teams_tip')}
					</h6>
				</FormGroup>
			</Col>
		</Row>
	);
};
